@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap);
html,
input,
body,
h1,
p,
button {
  font-family: 'Roboto', sans-serif;
}

body {
    margin:0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

p {
    font-size:1em;
}

.page {
    width:90%;
    margin:0 auto;
    /* border:1px solid #aaa; */
    text-align: center;
}

.page input {
    padding:10px;
    width:90%;
    border-radius: 6px;
    border:1px solid #bbb;
    font-size:1.1em;
}

.footer {
    font-size:0.9em;
    margin-top:50px;
}

.basic-btn {
    display:inline-block;
    width:auto;
    margin:0 auto;
    margin:5px;
    color:white;
    text-transform: uppercase;
    background-color: black;
    padding:10px;
    border-radius: 5px;
    margin-top:100px;
    margin-bottom:10px;
}

.small-btn {
    display:block;
    float:left;
    background-color: black;
    color:white;
    border-radius: 5px;
    padding:5px;
}

.placeCont {
    width:90%;
    margin:0 auto;
    margin-bottom:10px;
    padding:10px;
    border:1px solid black;
}

.placeCont h1 {
    font-size:1.1em;
    margin:0;
}

.choice {
    border-bottom:1px solid #bbb;
    margin-left:20px;
    margin-right:20px;
    padding-bottom:10px;
    padding-top:10px;
}

.choiceLabel {
    float:left;
}

.choiceCheck {
    float:right;
    width:auto;
    font-size:0.8em;
    color:red;
}

.choiceCheck input {
    width:auto;
}

.choiceText {
    width:90%;
    margin:0 auto;
    margin-top:25px;
}

.clearer {
    clear:both;
}

.invite {
    border:1px solid black;
    width:95%;
    margin:0 auto;
    padding:10px;
}

.inviteTitle {
    width:auto;
    text-align:left;
    font-weight: 700;
    font-size:1.2em;
}

.inviteGames {
    text-align:left;
}

.inviteSkill {
    text-align: left;
}

.inviteStakes {
    text-align: left;
}

.inviteActions {
    margin-top:15px;
}

.inviteActions div.small-btn {
    margin-right: 5px;
}
